import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { User, Access } from '../../../../types/User';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import StoreIcon from '@mui/icons-material/Store';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PublicIcon from '@mui/icons-material/Public';
import { useSnackbar } from '../../../SnackbarProvider';
import { apiCallWithToken } from '../../../apiCallWithToken';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { SUCCESS_COLOR } from '../../../../globals';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

interface IProps {
  user: User;
  handleChangeUserStatus: any;
}

interface UserData {
  icon: React.ReactNode;
  data: string;
}

export default function UserCard({ user, handleChangeUserStatus }: IProps) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [userAccesses, setUserAccesses] = React.useState<Access[]>(
    user.accesses,
  );
  const [selectedRole, setSelectedRole] = useState<string>(user.user_group);
  const { showSnackbar } = useSnackbar();

  const handleUserData = () => {
    const userData: UserData[] = [
      { icon: <Grid4x4Icon />, data: user.user_id },
      { icon: <PersonIcon />, data: `${user.first_name} ${user.last_name}` },
      { icon: <AlternateEmailIcon />, data: user.username },
      { icon: <StoreIcon />, data: user.company },
    ];

    return userData.map((x: UserData, i: number) => (
      <Grid
        key={i}
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="center"
        sx={{ py: 1 }}
      >
        <Grid item xs={2} sx={{ textAlign: 'center' }}>
          {x.icon}
        </Grid>
        <Grid item xs={10}>
          {x.data}
        </Grid>
      </Grid>
    ));
  };

  const onChangeAccess = (
    value: boolean,
    website: string,
    website_id: number,
  ) => {
    // Update the userAccesses state based on the new value for the specified website
    setUserAccesses((prevAccesses) =>
      prevAccesses.map((access) =>
        access.website_name === website
          ? { ...access, can_access: value }
          : access,
      ),
    );
    const changeAccesses = async (updatedAccesses: any) => {
      await apiCallWithToken(
        `/admin/users/${user.user_id}/change-access?website_id=${website_id}&can_access=${value}`,
        'PATCH',
      );
      showSnackbar('Access changed', SUCCESS_COLOR, 'white');
    };
    changeAccesses(value);
  };

  const handleUserAccesses = () => {
    const handleWebsitesLogos = (websiteName: string) => {
      if (websiteName === 'catalogue') {
        return <NewspaperIcon />;
      }
      if (websiteName === 'brightearth') {
        return <PublicIcon />;
      }
    };

    return userAccesses
      .filter((x: Access) => x.website_id === 1)
      .map((x: Access, i: number) => (
        <Grid
          key={i}
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="center"
          sx={{ py: 1 }}
        >
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            {handleWebsitesLogos(x.website_name)}
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'center' }}>
            {x.website_name}
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <Switch
              onChange={(e: any) =>
                onChangeAccess(e.target.checked, x.website_name, x.website_id)
              }
              checked={x.can_access}
            />
          </Grid>
        </Grid>
      ));
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const deleteUser = async () => {
    handleChangeUserStatus(user.user_id);
    await apiCallWithToken(`/admin/users/${user.user_id}`, 'DELETE');
    showSnackbar('User deleted', SUCCESS_COLOR, 'white');
    handleCloseDeleteDialog(); // Close the dialog after successful deletion
  };

  const changeUserGroup = async (newGroup: string) => {
    setSelectedRole(newGroup);
    await apiCallWithToken(
      `/admin/users/${user.user_id}/change-group?new_group=${newGroup}`,
      'PATCH',
    );
    showSnackbar(
      `Group changed to ${newGroup} for the user ${user.first_name} ${user.last_name}`,
      SUCCESS_COLOR,
      'white',
    );
    return;
  };

  return (
    <Paper elevation={3} sx={{ p: 2, maxWidth: 400, margin: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Avatar sx={{ backgroundColor: '#206294' }}>
          {user.first_name[0]}
          {user.last_name[0]}
        </Avatar>
      </Box>
      <Divider sx={{ mb: 2 }}>User information</Divider>
      <Grid container sx={{ mb: 2 }}>
        {handleUserData()}
      </Grid>
      <Divider sx={{ mb: 2 }}>Accesses</Divider>
      <Grid container sx={{ mb: 2 }}>
        {handleUserAccesses()}
      </Grid>
      <Divider sx={{ mb: 2 }}>Actions</Divider>
      <FormControl sx={{ width: '100%', mb: 2 }}>
        <InputLabel id="role-select-label">Select Group</InputLabel>
        <Select
          fullWidth
          size="small"
          labelId="role-select-label"
          id="role-select"
          value={selectedRole}
          label="Select Role"
          onChange={(e) => changeUserGroup(e.target.value as string)}
        >
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="premium">Premium</MenuItem>
          <MenuItem value="customer">Customer</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          sx={{ width: '100%' }}
          color="error"
          onClick={handleOpenDeleteDialog}
        >
          Delete user
        </Button>
      </Box>
      {/* Delete Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={deleteUser} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
